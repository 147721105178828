<template>
  <v-container
    id="support"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-data-table
      :headers="headers"
      :items="dataFiltered"
      :search="search"
      sort-by="date"
      :sort-desc="true"
      :no-data-text="str['no_data']"
      class="elevation-1"
      @click:row="openDialog"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="str['search']"
            single-line
            hide-details
          />
          <v-spacer />
          <v-btn
            color="secondary"
            dark
            class="mb-2 mr-2"
            @click="copyIssuesToClipboard()"
          >
            {{ str['export'] }}
          </v-btn>
          <v-btn
            color="success"
            dark
            class="mb-2"
            @click="openDialog()"
          >
            {{ str['add'] }}
          </v-btn>
        </v-toolbar>
        <v-toolbar
          flat
          class="toolbar-adjust"
        >
          <v-row>
            <v-col
              cols="3"
            >
              <v-menu
                v-model="filterDatesMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterDatesText"
                    :label="str['interval_dates']"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                  />
                </template>
                <v-date-picker
                  v-model="filterDates"
                  range
                  style="margin: 0;"
                  @change="refreshData"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="3"
            >
              <v-autocomplete
                v-model="ptIdFilter"
                :label="str['personal_trainer']"
                item-text="label"
                item-value="value"
                :items="ptsOptions"
                :no-data-text="str['no_data']"
                @change="refreshData"
                hide-details
              />
            </v-col>
            <v-col
              cols="3"
            >
              <v-autocomplete
                v-model="statusFilter"
                :label="str['status']"
                item-text="label"
                item-value="value"
                :items="issueStatusList"
                :no-data-text="str['no_data']"
                hide-details
              />
            </v-col>
            <v-col
              cols="3"
            >
              <v-autocomplete
                v-model="typeFilter"
                :label="str['type']"
                item-text="label"
                item-value="value"
                :items="issueTypesList"
                :no-data-text="str['no_data']"
                hide-details
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.type_str="{ item }">
        <span
          v-if="issueTypesDict[item.type]"
          v-bind:class="[issueTypesDict[item.type] && issueTypesDict[item.type].type ? (issueTypesDict[item.type].type + '--text') : '']"
        >
          {{ issueTypesDict[item.type].label }}
        </span>
      </template>
      <template v-slot:item.status_str="{ item }">
        <span
          v-if="issueStatusDict[item.status]"
          v-bind:class="[issueStatusDict[item.status] && issueStatusDict[item.status].type ? (issueStatusDict[item.status].type + '--text') : '']"
        >
          {{ issueStatusDict[item.status].label }}
        </span>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogItem"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ itemToEdit ? str['edit_issue'] : str['new_issue'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                v-for="item in fields"
                :key="item.id"
                :cols="item.cols ? item.cols : 12"
              >
                <v-text-field
                  v-if="item.type == 'input'"
                  v-model="item.value"
                  :label="item.title + (item.required ? ' *' : '')"
                  hide-details
                />
                <v-textarea
                  v-if="item.type == 'textarea'"
                  v-model="item.value"
                  :label="item.title + (item.required ? ' *' : '')"
                  hide-details
                />
                <v-autocomplete
                  v-if="item.type == 'select'"
                  v-model="item.value"
                  :label="item.title + (item.required ? ' *' : '')"
                  item-text="label"
                  item-value="value"
                  :items="item.items"
                  :no-data-text="str['no_data']"
                  hide-details
                  :disabled="item.disabled"
                  :search-input.sync="item.searchInput"
                  @change="item.searchInput=''"
                />
                <div v-if="item.type == 'image'">
                  <div>
                    {{ str['image'] + (item.required ? ' *' : '') }}
                  </div>
                  <div
                    v-if="item.value"
                    class="form-field-content-image"
                  >
                    <input
                      id="input-image"
                      type="file"
                      @change="addNewImage"
                      accept=".png,.jpg,.jpeg"
                    >
                    <v-img
                      contain
                      :src="item.value"
                      width="300px"
                      height="300px"
                    />
                  </div>
                  <div
                    v-if="!item.value"
                    class="form-field-content-image"
                  >
                    <input
                      id="input-image"
                      type="file"
                      @change="addNewImage"
                      accept=".png,.jpg,.jpeg"
                    >
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="success"
                    >
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="closeDialog()"
          >
            {{ str['cancel'] }}
          </v-btn>
          <!-- <v-btn
            v-if="itemToEdit"
            color="error"
            @click="deleteItem()"
          >
            {{ str['delete'] }}
          </v-btn> -->
          <v-btn
            color="success"
            @click="saveItem()"
            :disabled="!fieldsValidated()"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'
  import moment from 'moment'

  export default {
    data () {
      return {
        str: window.strings,
        issueTypesList: this.getIssueTypesList(),
        issueTypesDict: this.getIssueTypesDict(),
        issueStatusList: this.getIssueStatusList(),
        issueStatusDict: this.getIssueStatusDict(),
        data: [],
        search: '',
        headers: [
          { text: window.strings['id'], value: 'id', align: 'left', width: 60 },
          { text: window.strings['pt_id'], value: 'pt_id', align: 'center', width: 100 },
          { text: window.strings['name'], value: 'pt_name', align: 'center', width: 100 },
          { text: window.strings['date'], value: 'date', align: 'center', width: 150 },
          { text: window.strings['status'], value: 'status_str', align: 'center', width: 150 },
          { text: window.strings['title'], value: 'title', align: 'center', width: 250 },
          { text: window.strings['type'], value: 'type_str', align: 'center', width: 150 },
          { text: window.strings['client_id'], value: 'user_id', align: 'center', width: 150 },
          { text: window.strings['email'], value: 'email', align: 'center', width: 240 },
          { text: window.strings['mobile_phone'], value: 'phone', align: 'center', width: 170 },
        ],
        fields: [{
          type: 'select',
          id: 'pt_id',
          title: window.strings['pt_id'],
          items: [],
          value: '',
          searchInput: '',
          required: true,
          cols: 4,
        }, {
          type: 'select',
          id: 'type',
          title: window.strings['type'],
          items: Utils.getReportIssueTypes(),
          value: '',
          searchInput: '',
          required: true,
          cols: 4,
        }, {
          type: 'select',
          id: 'status',
          title: window.strings['status'],
          items: Utils.getReportIssueStatus(),
          value: '',
          searchInput: '',
          required: true,
          cols: 4,
        }, {
          type: 'input',
          id: 'title',
          title: window.strings['title'],
          value: '',
          required: true,
        }, {
          type: 'textarea',
          id: 'description',
          title: window.strings['description'],
          value: '',
          required: true,
        }, {
          type: 'input',
          id: 'user_id',
          title: window.strings['client_id'],
          value: '',
          number: true,
          required: false,
          cols: 4,
        }, {
          type: 'input',
          id: 'email',
          title: window.strings['email'],
          value: '',
          required: false,
          cols: 8,
        }, {
          type: 'input',
          id: 'phone',
          title: window.strings['mobile_phone'],
          value: '',
          required: false,
          cols: 4,
        }, {
          type: 'input',
          id: 'name',
          title: window.strings['name'],
          value: '',
          required: false,
          cols: 8,
        }, {
          type: 'input',
          id: 'device',
          title: window.strings['device'],
          value: '',
          required: false,
        }, {
          type: 'input',
          id: 'attachment',
          title: window.strings['attachment'] + ' (' + window.strings['url'] + ')',
          value: '',
          required: false,
        }, {
          type: 'image',
          id: 'images',
          title: window.strings['images'],
          value: '',
          required: false,
        }],
        dialogItem: false,
        itemToEdit: null,
        imageMaxSize: 300,
        statusFilter: '',
        typeFilter: '',
        ptsOptions: [{
          label: 'Tiago Guimarães',
          value: window.ptTgId
        }, {
          label: 'Francisco Macau',
          value: window.ptFmId
        }, {
          label: window.strings['others'],
          value: 'others'
        }],
        ptIdFilter: window.ptTgId,
        filterDates: [moment().subtract(5, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        filterDatesMenu: false,
        ptsIdsDict: {},
      }
    },
    watch: {
      dialogItem (val) {
        const dialog = document.getElementsByClassName('v-dialog')
        if (dialog && dialog[0]) {
          dialog[0].scrollTo(0, 0)
        }
        val || this.closeDialog()
      },
    },
    computed: {
      dataFiltered() {
        const self = this;
        let data = this.data
        if (this.statusFilter !== null && this.statusFilter !== '') {
          data = data.filter(function (item) {
            return item.status === self.statusFilter
          })
        }
        if (this.typeFilter !== null && this.typeFilter !== '') {
          data = data.filter(function (item) {
            return item.type === self.typeFilter
          })
        }
        return data
      },
      filterDatesText () {
        return this.filterDates.join(' / ')
      },
    },
    beforeMount: function () {
      this.$isLoading(true)
      this.getPtsIds()
    },
    methods: {
      getIssueStatusList: function () {
        const items = JSON.parse(JSON.stringify(Utils.getReportIssueStatus()))
        items.unshift({
          label: window.strings['all'],
          value: ''
        })
        return items
      },
      getIssueStatusDict: function () {
        const items = Utils.getReportIssueStatus()
        const dict = {}
        if (items && items.length) {
          items.forEach(function (item) {
            dict[item.value] = item
          })
        }
        return dict
      },
      getIssueTypesList: function () {
        const items = JSON.parse(JSON.stringify(Utils.getReportIssueTypes()))
        items.unshift({
          label: window.strings['all'],
          value: ''
        })
        return items
      },
      getIssueTypesDict: function () {
        const items = Utils.getReportIssueTypes()
        const dict = {}
        if (items && items.length) {
          items.forEach(function (item) {
            dict[item.value] = item
          })
        }
        return dict
      },
      getPtsIds: function () {
        const self = this
        Api.getActiveClients(false, {
          fields: ['id', 'name']
        }, function (response) {
          if (response.success) {
            const ptsIds = response.data.sort(function(a, b) {
              return b.id - a.id
            }).map(a => ({label: a.name, value: a.id}))
            for (let i = 0; i < self.fields.length; i++) {
              if (self.fields[i].id === 'pt_id') {
                self.fields[i].items = ptsIds
                break
              }
            }
            self.ptsIdsDict = ptsIds.reduce((dict, p) => {
              dict[p.value] = p.label
              return dict
            }, {})
            self.getIssues()
          } else {
            self.$isLoading(false)
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getIssues: function () {
        const self = this
        this.$isLoading(true)
        Api.getReportIssues(this.ptIdFilter, {
          start_date: this.filterDates[0] + ' 00:00:00',
          end_date: this.filterDates[1] + ' 23:59:59'
        }, function (response) {
          self.$isLoading(false)
          console.log(self.ptsIdsDict)
          if (response.success) {
            for (let i = 0; i < response.data.length; i++) {
              response.data[i].status_str = self.issueStatusDict[response.data[i].status] ? self.issueStatusDict[response.data[i].status].label : response.data[i].status
              response.data[i].type_str = self.issueTypesDict[response.data[i].type] ? self.issueTypesDict[response.data[i].type].label : response.data[i].type
              response.data[i].pt_name = self.ptsIdsDict[response.data[i].pt_id] ? self.ptsIdsDict[response.data[i].pt_id] : '-'
            }
            self.data = response.data
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      refreshData() {
        this.filterDatesMenu = false
        this.getIssues()
      },
      openDialog(item) {
        this.itemToEdit = item
        this.setData()
        this.dialogItem = true
      },
      closeDialog() {
        this.dialogItem = false
        this.itemToEdit = null
      },
      fieldsValidated() {
        for (let i = 0; i < this.fields.length; i++) {
          if (this.fields[i].required && !this.fields[i].value && this.fields[i].value !== 0) {
            return false
          }
        }
        return true
      },
      addNewImage (event) {
        const self = this
        const file = event.srcElement.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function (e) {
          const image = new Image()
          image.src = reader.result
          image.onload = function () {
            let out = null
            if (image.width < self.imageMaxSize && image.height < self.imageMaxSize) {
              out = e.target.result
            } else {
              out = resizeImage(image)
            }
            self.saveImage(out)
          }
        }
        reader.onerror = function () {}

        function resizeImage (image) {
          const canvas = document.createElement('canvas')
          let width = image.width
          let height = image.height
          if (width > height) {
            if (width > self.imageMaxSize) {
              height *= self.imageMaxSize / width
              width = self.imageMaxSize
            }
          } else {
            if (height > self.imageMaxSize) {
              width *= self.imageMaxSize / height
              height = self.imageMaxSize
            }
          }
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0, width, height)
          return canvas.toDataURL()
        }
      },
      saveImage(image) {
        for (let i = 0; i < this.fields.length; i++) {
          if (this.fields[i].id === 'images') {
            this.fields[i].value = image
            this.$set(this.fields, i, this.fields[i])
            break
          }
        }
        document.getElementById('input-image').value = ''
      },
      saveItem() {
        const self = this
        const data = this.getData()
        if (data) {
          this.$isLoading(true)
          if (this.itemToEdit) {
            data.id = this.itemToEdit.id
            Api.updateReportIssue(data.pt_id, data, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.closeDialog()
                self.getIssues()
              } else {
                self.$alert(
                  response.message,
                  window.strings['warning'],
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          } else {
            Api.newReportIssue(data.pt_id, data, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.closeDialog()
                self.getIssues()
              } else {
                self.$alert(
                  response.message,
                  window.strings['warning'],
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          }
        }
      },
      getData() {
        const data = {}
        for (let i = 0; i < this.fields.length; i++) {
          if (this.fields[i].required && !this.fields[i].value && this.fields[i].value !== 0) {
            return false
          }
          if (this.fields[i].id === 'images') {
            data[this.fields[i].id] = this.fields[i].value ? JSON.stringify([this.fields[i].value]) : ''
          } else {
            if (this.fields[i].number) {
              data[this.fields[i].id] = parseInt(this.fields[i].value)
            } else {
              data[this.fields[i].id] = this.fields[i].value
            }
          }
        }
        return data
      },
      setData() {
        for (let i = 0; i < this.fields.length; i++) {
          if (this.fields[i].id === 'images') {
            this.fields[i].value = this.itemToEdit && this.itemToEdit.images ? JSON.parse(this.itemToEdit.images)[0] : null
          } else {
            this.fields[i].value = this.itemToEdit && (this.itemToEdit[this.fields[i].id] || this.itemToEdit[this.fields[i].id] === 0) ? this.itemToEdit[this.fields[i].id] : ''
          }
        }
      },
      deleteItem() {
        const self = this
        this.$confirm(
          window.strings['are_you_sure_delete'],
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.$isLoading(true)
          Api.deleteReportIssue(self.itemToEdit.pt_id, {
            id: self.itemToEdit.id
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.getIssues()
            } else {
              self.$alert(
                response.message,
                window.strings['warning'],
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => {})
      },
      copyIssuesToClipboard() {
        if (navigator && navigator.clipboard) {
          let value = ''
          this.data.forEach(function (d) {
            if (value) {
              value += '\n'
            }
            value += '-> ' + d.type_str + ': ' + d.title
          })
          const textarea = document.createElement('textarea')
          textarea.value = value
          document.body.appendChild(textarea)
          textarea.select()
          document.execCommand('copy')
          document.body.removeChild(textarea)
          this.$alert(
            window.strings['data_copied'],
            '',
            'success',
            Utils.getAlertOptions()
          )
        }
      },
    },
  }
</script>
